<template lang="html">
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title>
        {{ $tc('label.usuario_fornecedor', 1) }}
      </v-card-title>
      <v-container grid-list-xl fluid v-show="isPaginaCarregada">
        <v-row>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              :label="`${$t('label.email')} *`"
              ref="email"
              v-model="usuario.email"
              required
              :rules="[rules.requiredText, rules.isEmailValid]"
              maxlength="150"
              clearable
              counter
              :disabled="somenteLeitura || encontrouOmni">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-btn
              id="btn_buscar_usario_omni"
              :disabled="somenteLeitura || usuario.ehEdicao() || encontrouOmni"
              @click.native="buscarUsuarioOmni">
              {{ $t('label.buscar') }}
            </v-btn>
            <v-btn
              id="btn_limpar_busca"
              class="ml-3"
              :disabled="somenteLeitura || usuario.ehEdicao()"
              @click.native="limparFormulario">
              {{ $t('label.limpar') }}
            </v-btn>
          </v-col>
        </v-row>
        <metadados-container-layout
          v-if="metadadosUsuario"
          :metadados-entidade="metadadosUsuario"
          :objeto="usuario"
          :input-layout="inputLayout"
          :layout-class="layoutClass"
          :somente-leitura="somenteLeitura || isUsuarioExternoMaster"
          ref="containerUsuario">
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              :label="`${$t('label.login')} *`"
              v-model="usuario.nomUsuario"
              required
              :rules="[rules.requiredText]"
              maxlength="100"
              counter
              :disabled="!isNovoCadastro || somenteLeitura || encontrouOmni"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-autocomplete
              id="usuario-perfil"
              class="custom-autocomplete"
              :disabled="somenteLeitura || isUsuarioExternoMaster"
              required
              :rules="[rules.required]"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.perfil', 1)} *`"
              v-model="usuario.idPerfil"
              :items="perfis"
              item-text="nomPerfil"
              item-value="id"
              clearable>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              :label="`${$t('label.nome')} *`"
              v-model="usuario.nome"
              required
              :rules="[rules.requiredText]"
              maxlength="100"
              counter
              :disabled="somenteLeitura"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              :label="$t('label.sobrenome')"
              v-model="usuario.sobrenome"
              maxlength="150"
              counter
              :disabled="somenteLeitura"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              :label="`${$t('label.cargo')} *`"
              v-model="usuario.cargo"
              maxlength="50"
              counter
              required
              :rules="[rules.requiredText]"
              :disabled="somenteLeitura"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              :label="`${$t('label.documento')} *`"
              v-model="usuario.documento"
              maxlength="50"
              counter
              required
              :rules="[rules.requiredText]"
              :disabled="somenteLeitura"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" slot="depois">
            <v-switch
              color="primary"
              :label="$t('label.recebe_mail')"
              :disabled="somenteLeitura"
              v-model="usuario.indRecebeMail">
            </v-switch>
          </v-col>
          <v-col cols="12" sm="2" slot="depois" :key="panelKey">
            <v-switch
              color="primary"
              :label="$t('label.ativo')"
              :disabled="desabilitarFlagAtivo"
              @change="changeIndAtivo(usuario)"
              v-model="usuario.indAtivo">
            </v-switch>
          </v-col>
          <v-col cols="12" sm="4" slot="depois">
          </v-col>
          <v-col cols="12" sm="2" slot="depois">
            <v-switch
              color="primary"
              :label="$t('label.tipos_fornecedor.representante_legal')"
              :disabled="desabilitarFlagUsuarioMaster"
              @click="triggerAtivaUsuarioMaster()"
              v-model="usuario.indUsuarioExternoMaster">
            </v-switch>
          </v-col>
          <v-col cols="12" sm="2" slot="depois">
            <v-switch
              color="primary"
              :label="$t('label.procurador')"
              :disabled="somenteLeitura || !usuarioLogadoFuncionario
               || !podeCadastrarFornecedorRepresentanteLegal || !usuario.indUsuarioExternoMaster"
              v-model="usuario.indProcurador"
              @click="isProcurador(usuario.indProcurador)"
              >
            </v-switch>
          </v-col >
          <v-col cols="12" md="2" slot="depois" v-if="habilitaCampoDataSemIndProcurador || (this.usuario.indUsuarioExternoMaster && this.flagProcurador)">
            <v-menu
              :key="menuKey"
              v-model="menuInicio"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  :label="`${$tc('label.inicio_vigencia', 1)} * `"
                  :rules="[rules.requiredVigencia, rules.validDate]"
                  :disabled="somenteLeitura || !usuario.indProcurador"
                  v-model="dtaInicioVigenciaFormatada"
                  :return-masked-value="true"
                  v-mask="'##/##/####'"
                  prepend-icon="event"
                  :hint="!!usuario.dtaInicioVigenciaPrevia ? `${$tc('label.nova_data_em_analise', 1)}: ` + dtaInicioVigenciaPreviaFormatada : ''"
                  :persistent-hint="!!usuario.dtaInicioVigenciaPrevia"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="pt-br"
                v-model="dtaInicioVigencia"
                @input="menuInicio = false, setarDataInicioNoUsuario()"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2" slot="depois" v-if="habilitaCampoDataSemIndProcurador || (this.usuario.indUsuarioExternoMaster && this.flagProcurador)">
            <v-menu
              v-model="menuFim"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  :label="`${$tc('label.fim_vigencia', 1)} * `"
                  :rules="[rules.requiredVigencia, rules.validDate, rules.dataFimMaiorInicio, rules.dataFimVencida]"
                  :disabled="somenteLeitura || !usuario.indProcurador"
                  v-model="dtaFimVigenciaFormatada"
                  :return-masked-value="true"
                  v-mask="'##/##/####'"
                  prepend-icon="event"
                  :hint="!!usuario.dtaFimVigenciaPrevia ? `${$tc('label.nova_data_em_analise', 1)}: ` + dtaFimVigenciaPreviaFormatada : ''"
                  :persistent-hint="!!usuario.dtaFimVigenciaPrevia"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="pt-br"
                v-model="dtaFimVigencia"
                @input="menuFim = false, setarDataFimNoUsuario()"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="2" slot="depois" v-if="usuario.indUsuarioExternoMaster">
            <v-switch
              color="primary"
              :label="$t('label.representacao_conjunto')"
              :disabled="somenteLeitura || !podeCadastrarFornecedorRepresentanteLegal"
              v-model="usuario.indRepresentacaoConjunto"
              @click="representacaoConjuntoAlterada()"
              >
            </v-switch>
          </v-col >
          <v-col :key="aprovadorKey" cols="12" sm="2" slot="depois" v-show="!usuarioLogadoFornecedor && usuario.indRepresentacaoConjunto">
            <v-switch
              color="primary"
              :label="$t('label.aprovador_anexo_comercial')"
              :disabled="somenteLeitura || !podeCadastrarFornecedorRepresentanteLegal"
              v-model="usuario.indAprovadorAnexoComercial"
              >
            </v-switch>
          </v-col >
        </metadados-container-layout>
        <div v-if="usuario.indUsuarioExternoMaster && !usuario.indProcurador">{{$tc('message.vigencia_representante_legal')}}</div>

        <div v-if="usuario.indUsuarioExternoMaster && (podeCadastrarAnexos || podeVisualizarAnexos)">
          <div class="mt-3 mb-2 title-float accent--text">
            {{$tc('label.anexo', 2)}}
          </div>
          <v-card>
            <v-container fluid grid-list-md>
              <usuario-form-arquivo
                :is-novo-cadastro="isNovoCadastro"
                :usuario="usuario"
                :pode-visualizar-anexos="!!(podeVisualizarAnexos && usuario.id && isUsuarioFornecedor)"
                :pode-cadastrar-anexos="!!(podeCadastrarAnexos && isUsuarioFornecedor && usuario.id)"
                :habilita-anexo-fornecedor="true"
                @FormAnexoArquivo__qtde="setQuantidadeArquivos">
              </usuario-form-arquivo>
            </v-container>
          </v-card>
        </div>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="somenteLeitura" @click.native="cancel">{{ $t('label.voltar') }}</v-btn>
        <v-btn v-if="!somenteLeitura" @click.native="openCancelarDialog">{{ $t('label.cancelar') }}</v-btn>
        <v-btn v-if="!somenteLeitura" @click.native="openSaveDialog" color="primary">{{ $t('label.salvar') }}</v-btn>
        <v-btn @click=""
          class="mr-3"
          dark
          color="red"
          @click.native="openSolicitarAprovacaoDialog"
          v-show="!isUsuarioAprovador && (podeSolicitarAprovacao || (usuarioAprovadoNaoProcurador && usuario.indProcurador))">
          {{ $t('label.solicitar_aprovacao') }}
        </v-btn>
        <v-btn @click=""
          dark
          color="primary"
          @click.native="openModalJustificativaAprovacao('APROVADO', $t('label.aprovar'))"
          v-show="podeAprovarReprovarRepresentanteLegal">
          {{ $t('label.aprovar') }}
        </v-btn>
        <v-btn @click=""
          class="mr-3"
          dark
          color="red"
          @click.native="openModalJustificativaAprovacao('REPROVADO', $t('label.reprovar'))"
          v-show="podeAprovarReprovarRepresentanteLegal">
          {{ $t('label.reprovar') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <confirm
      ref="confirmDialog"
      :message="dialog.content"
      :persistent="true"
      @agree="save()">
    </confirm>

    <confirm
      ref="confirmDialogAlterandoRepConjuntoGrupo"
      :persistent="true"
      :agree-label="$t('label.sim')"
      :disagree-label="$t('label.nao')">
    </confirm>

    <confirm
      ref="confirmDialogSolicitarAprovacao"
      :persistent="true"
      :max-width="'370'"
      :agree-label="$t('label.sim')"
      :disagree-label="$t('label.nao')"/>

    <confirm
      ref="cancelarDialog" :title="$t('title.atencao')"
      :message="$t('message.tem_certeza_cancelar')"
      @agree="cancel">
    </confirm>

    <confirm
      ref="avisoAnexoDialog" :title="$t('title.atencao')"
      :message="$t('message.aviso_anexo_obrigatorio')"
      :hide-disagree="true"
      :persistent="true"
      :agree-label="$t('label.ok')"
      @agree="desativarUsuario">
    </confirm>

    <confirm
      ref="avisoAnexoUsuarioAntigoDialog" :title="$t('title.atencao')"
      :message="$t('message.aviso_anexo_obrigatorio_solicitar_aprovacao_usuario')"
      :persistent="true"
      :hide-disagree="true"
      :agree-label="$t('label.ok')">
    </confirm>

    <usuario-form-termo-aceite-dialog
      ref="termoAceiteDialog"
      :termo-aceite="termoAceite"
      @Finalizar="finalizar">
    </usuario-form-termo-aceite-dialog>
    <usuario-aprovacao-dialog
      ref="modalJustificativaAprovacao"
      :title="titleJustificativaModal"
      :obrigatorio="statusAprovacaoUsuario !== 'APROVADO'"
      @Afirmativo="aprovarReprovarUsuario">
    </usuario-aprovacao-dialog>
    <usuario-data-alteracao-dialog
      ref="modalDataAlteracao"
      :nome-perfil-aprovador-fluxo="usuario.nomePerfilAprovador"
      @CancelarEdicaoData="cancelarEdicaoData"
      @SolicitarAprovacaoEdicaoData="openSolicitarAprovacaoDialog">
    </usuario-data-alteracao-dialog>
    <modal-dados-fluxos-de-aprovacao-impactados-por-inativacao-usuario
     ref="modalDadosFluxosDeAprovacaoImpactadosPorInativacaoUsuario"
     @disagree="cancelaAcaoInativar"
     @agree="alterarStatusEntidadesImpactadasPorInativacaoUsuario"
     :existe-fluxos-impactados-por-inativacao="existeFluxosImpactadosPorInativacao"
    >
    </modal-dados-fluxos-de-aprovacao-impactados-por-inativacao-usuario>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { montarEstruturaDinamica } from '../../common/functions/metadados';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import { copyObject } from '../../common/functions/helpers';
import { enviarEmailAvisoInativacaoFornecedor } from '../../common/resources/workflow-acao';
import Usuario from './Usuario';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import MetadadosContainerLayout from '../../shared-components/metadados/MetadadosContainerLayout';
import UsuarioFormArquivo from './UsuarioFormArquivo';
import UsuarioFormTermoAceiteDialog from './UsuarioFormTermoAceiteDialog';
import UsuarioAprovacaoDialog from './UsuarioAprovacaoDialog';
import UsuarioDataAlteracaoDialog from './UsuarioDataAlteracaoDialog';
import ModalDadosFluxosDeAprovacaoImpactadosPorInativacaoUsuario from '../../shared-components/ModalDadosFluxosDeAprovacaoImpactadosPorInativacaoUsuario';
import {
  formatDateDDMMYYYYBarras,
  parseDateYYYYMMDDBarras,
} from '../../common/functions/date-utils';
import {
  forceHideLoading,
  forceLoading,
  skipLoading,
} from '../../common/functions/loading';

export default {
  name: 'UsuarioFornecedorForm',
  props: {
    somenteLeitura: Boolean,
    default: false,
  },
  components: {
    Confirm,
    MetadadosContainerLayout,
    UsuarioFormArquivo,
    UsuarioFormTermoAceiteDialog,
    UsuarioAprovacaoDialog,
    UsuarioDataAlteracaoDialog,
    ModalDadosFluxosDeAprovacaoImpactadosPorInativacaoUsuario,
  },
  computed: {
    ...mapGetters('metadados', [
      'getUsuarioMetadado',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
      'getTenantId',
    ]),
    ...mapGetters('implantacao', [
      'indAmbienteProdutivo',
    ]),
    ...generateComputed('usr', [
      'canPost',
      'canEdit',
      'canSearch',
      'canAccessCRUD',
    ]),
    dtaInicioVigenciaFormatada: {
      get() {
        return this.dtaInicioVigencia ? formatDateDDMMYYYYBarras(this.dtaInicioVigencia) : null;
      },
      set(newValue) {
        this.dtaInicioVigencia = newValue ? parseDateYYYYMMDDBarras(newValue) : null;
        this.usuario.dtaInicioVigencia = newValue ? parseDateYYYYMMDDBarras(newValue) : null;
      },
    },
    dtaFimVigenciaFormatada: {
      get() {
        return this.dtaFimVigencia ? formatDateDDMMYYYYBarras(this.dtaFimVigencia) : null;
      },
      set(newValue) {
        this.dtaFimVigencia = newValue ? parseDateYYYYMMDDBarras(newValue) : null;
        this.usuario.dtaFimVigencia = newValue ? parseDateYYYYMMDDBarras(newValue) : null;
      },
    },
    dtaInicioVigenciaPreviaFormatada: {
      get() {
        return this.usuario.dtaInicioVigenciaPrevia ? formatDateDDMMYYYYBarras(this.usuario.dtaInicioVigenciaPrevia) : null;
      },
      set(newValue) {
        this.usuario.dtaInicioVigenciaPrevia = newValue ? parseDateYYYYMMDDBarras(newValue) : null;
      },
    },
    dtaFimVigenciaPreviaFormatada: {
      get() {
        return this.usuario.dtaFimVigenciaPrevia ? formatDateDDMMYYYYBarras(this.usuario.dtaFimVigenciaPrevia) : null;
      },
      set(newValue) {
        this.usuario.dtaFimVigenciaPrevia = newValue ? parseDateYYYYMMDDBarras(newValue) : null;
      },
    },
    podeCadastrarFornecedorRepresentanteLegal() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'USR_CADASTRAR_GESTAO_DE_FORNECEDOR_REPRESENTANTE_LEGAL').length;
    },
    podeCadastrarFornecedorMaster() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'USR_CADASTRAR_GESTAO_DE_FORNECEDOR_MASTER').length;
    },
    permissaoSolicitarAprovacao() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'SOLICITAR_APROVACAO_USUARIO').length;
    },
    isNovoCadastro() {
      return !this.idUsuario && !this.usuario.id;
    },
    usuarioLogadoFuncionario() {
      return this.usuarioLogado.tipo === 'FUNCIONARIO';
    },
    usuarioLogadoFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
    metadadosUsuario() {
      if (this.getUsuarioMetadado && this.getUsuarioMetadado.mapaEntidades) {
        if (!this.isUsuarioFuncionario && this.getUsuarioMetadado.mapaEntidades.grupofornecedor) {
          delete this.getUsuarioMetadado.mapaEntidades.grupofornecedor;
          return this.getUsuarioMetadado;
        }
        return this.getUsuarioMetadado;
      }
      return undefined;
    },
    isUsuarioExternoMaster() {
      return this.usuarioLogado.indUsuarioExternoMaster;
    },
    isUsuarioFuncionario() {
      return this.usuarioLogado.tipo === 'FUNCIONARIO';
    },
    podeVisualizarAnexos() {
      return this.canSearch;
    },
    podeCadastrarAnexos() {
      return this.canAccessCRUD && this.isUsuarioFuncionario;
    },
    podeSolicitarAprovacao() {
      return ((this.usuario.statusFluxo && !['AGUARDANDO_APROVACAO', 'APROVADO', 'EM_ANALISE'].includes(this.usuario.statusFluxo))
      || (!this.usuario.statusFluxo && this.usuario.id))
       && this.usuario.indUsuarioExternoMaster && this.indFluxoAprovacaoRepreLegal && this.permissaoSolicitarAprovacao && !this.usuario.indAtivo;
    },
    podeAprovarReprovarRepresentanteLegal() {
      return this.usuarioLogado.idPerfil === parseInt(this.usuario.perfilAprovador, 10)
        && ['AGUARDANDO_APROVACAO', 'EM_ANALISE'].includes(this.usuario.statusFluxo) && this.quantidadeArquivos >= 1;
    },
    habilitaCampoDataSemIndProcurador() {
      return this.usuario.indUsuarioExternoMaster && !this.flagProcurador && !this.isNovoCadastro
       && ((this.usuario.statusFluxo && this.usuario.statusFluxo === 'APROVADO') || !this.usuario.statusFluxo)
       && this.usuario.dtaInicioVigencia && this.usuario.dtaFimVigencia && !this.dataApagada;
    },
    isDataAlterada() {
      return ((this.usuario.dtaInicioVigencia && this.dtaInicioVigenciaOriginal !== this.usuario.dtaInicioVigencia)
        || (this.usuario.dtaFimVigencia && this.dtaFimVigenciaOriginal !== this.usuario.dtaFimVigencia));
    },
    desabilitarFlagUsuarioMaster() {
      return this.somenteLeitura || (this.podeCadastrarFornecedorRepresentanteLegal && !this.podeCadastrarFornecedorMaster)
      || (this.podeCadastrarFornecedorMaster && !this.podeCadastrarFornecedorRepresentanteLegal) || this.encontrouOmni
      || (!this.isNovoCadastro && this.usuario.indProcuradorVigenciaExpirada);
    },
    desabilitarFlagAtivo() {
      return this.somenteLeitura
      || (!this.isNovoCadastro && ['AGUARDANDO_APROVACAO', 'EM_ANALISE'].includes(this.usuario.statusFluxo) && !this.usuario.indAtivo);
    },
    isUsuarioFornecedor() {
      return this.usuario.tipo === 'FORNECEDOR';
    },
    isUsuarioAprovador() {
      return this.usuarioLogado.idPerfil === this.idPerfilAprovador;
    },
  },
  data() {
    return {
      usuarioResource: this.$api.usuario(this.$resource),
      perfilResource: this.$api.perfil(this.$resource),
      extensaoResources: this.$api.extensao(this.$resource),
      resourceMetadado: this.$api.metadado(this.$resource),
      termoAceiteResources: this.$api.termoAceite(this.$resource),
      configSistemaResource: this.$api.configuracaoSistema(this.$resource),
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      workflowContratoResource: this.$api.workflowContrato(this.$resource),
      dialog: {
        ref: 'dialog1',
        from: 'saveRegisterFromButton',
        title: this.$t('label.atencao'),
        content: '',
        action() {},
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredText: (value) => (!!value && !!value.trim()) || this.$t('message.campo_obrigatorio'),
        requiredTags: (value) => value.length > 0 || this.$t('message.campo_obrigatorio'),
        isEmailValid: (value) => this.emailReg.test(value) || this.$t('message.campo_obrigatorio_ou_invalido'),
        requiredVigencia: (value) => (!!value && !!value.trim()) || this.$t('message.campo_obrigatorio'),
        dataFimMaiorInicio: () => this.isDataFimMaiorInicio() || this.$t('message.data_inicial_maior_final'),
        dataFimVencida: () => this.isDataNaoVencida() || this.$t('message.data_final_menor_data_atual'),
        validDate: (value) => (!value || (value.length === 10 && this.moment(value, 'DD/MM/YYYY').isValid())) || this.$t('message.data_invalida'),
      },
      perfis: [],
      extensoes: [],
      niveisExtensoes: [],
      nivel: {},
      dadosFluxosAprovacaoImpactados: {},
      indFluxoAprovacaoRepreLegal: false,

      usuario: new Usuario(null, 'FORNECEDOR'),
      grupoFornecedorEdicao: {},
      idGrupoFornecedorEdicao: null,
      usuarioAprovadoNaoProcurador: false,

      inputLayout: {
        xs12: true,
        sm12: false,
        sm6: true,
        md3: false,
        md4: true,
        md6: false,
        md9: false,
        md12: false,
      },

      layoutClass: { wrap: true },

      termoAceite: null,
      quantidadeArquivos: 0,
      menuInicio: false,
      menuFim: false,
      flagProcurador: false,
      emailReg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      statusAprovacaoUsuario: '',
      justificativaAprovacaoReprovacaoUsuario: '',
      titleJustificativaModal: '',
      dtaInicioVigenciaOriginal: '',
      dtaFimVigenciaOriginal: '',
      menuKey: 0,
      usuarioSemEdicao: {},
      existeTermoAceite: false,
      aprovadorKey: 999,
      isPaginaCarregada: false,
      panelKey: 0,
      encontrouOmni: false,
      dtaInicioVigencia: '',
      dtaFimVigencia: '',
      idPerfilAprovador: null,
      indAtivoOriginal: false,
      indHabilitaAlterarFluxosImpactados: false,
      desabilitaFluxoAprovacaoUsuarioInativo: false,
      dataApagada: false,
      existeFluxosImpactadosPorInativacao: false,
    };
  },
  methods: {
    copyObject,
    ...mapActions('metadados', [
      'setUsuarioMetadado',
    ]),
    isProcurador(isProcurador, carregouTela = false) {
      this.flagProcurador = isProcurador;
      if (!isProcurador && !carregouTela) {
        this.usuario.dtaFimVigencia = null;
        this.usuario.dtaInicioVigencia = null;
        this.dtaFimVigenciaFormatada = null;
        this.dtaInicioVigenciaFormatada = null;
        this.dataApagada = true;
      }
      if (isProcurador && this.usuarioAprovadoNaoProcurador) {
        this.usuario.indAtivo = false;
      }
      this.$forceUpdate();
    },
    buscarUsuarioOmni() {
      if (!this.$refs.email.validate(true)) return;

      const { tipo, email } = this.usuario;
      const filtro = { tipo, email };

      if (this.podeCadastrarFornecedorRepresentanteLegal && !this.podeCadastrarFornecedorMaster) filtro.indUsuarioExternoMaster = true;
      else if (!this.podeCadastrarFornecedorRepresentanteLegal && this.podeCadastrarFornecedorMaster) filtro.indUsuarioExternoMaster = false;

      this.usuarioResource.buscarNoOmni(filtro)
        .then((res) => {
          const usuarioOmni = res.data;
          this.usuario.construirAPartirUsuarioOmni(usuarioOmni);
          const perfil = this.perfis.find((p) => p.idExterno === usuarioOmni.idPerfil);
          this.usuario.idPerfil = perfil ? perfil.id : null;
          this.encontrouOmni = true;
          this.isProcurador(this.usuario.indProcurador);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    popularMetadadosUsuario() {
      if (this.getUsuarioMetadado) {
        return;
      }
      this.setUsuarioMetadado({
        resource: this.resourceMetadado.listarUsuario,
      });
    },
    limparFormulario() {
      this.usuario = new Usuario(null, 'FORNECEDOR');
      this.encontrouOmni = false;
      this.$refs.form.reset();
      setTimeout(() => {
        if (this.podeCadastrarFornecedorRepresentanteLegal && !this.podeCadastrarFornecedorMaster) this.usuario.indUsuarioExternoMaster = true;
        this.usuario.indRecebeMail = true;
        this.isProcurador(this.usuario.indProcurador);
        this.selecionarPerfilAuto();
        this.indAtivoPadrao();
      }, 1e2);
    },
    cancel() {
      this.limparFormulario();
      this.$router.push({ name: 'usuario-fornecedor' });
    },
    openCancelarDialog() {
      this.$refs.cancelarDialog.open();
    },
    finalizar() {
      this.$refs.confirmDialog.open();
    },
    openSaveDialog(ref, from) {
      if (!this.$refs.form.validate()) return;
      const content = this.$t('message.salvar_usuario');
      this.setDialogContent(content, this.save, from || this.dialog.from);
      if (this.termoAceite && this.termoAceite.indAtivo && !this.existeTermoAceite
        && !this.usuario.indUsuarioExternoMaster && this.usuarioLogadoFornecedor) {
        this.$refs.termoAceiteDialog.open(this.usuario.email, 'razaoSocial', `${this.usuario.nome} ${this.usuario.sobrenome ? this.usuario.sobrenome : ''}`);
      } else if (this.usuario.indUsuarioExternoMaster && this.podeCadastrarAnexos
          && this.quantidadeArquivos < 1 && !this.usuario.indAtivo) {
        this.save();
      } else if (this.usuario.indUsuarioExternoMaster && this.podeCadastrarAnexos
          && this.quantidadeArquivos < 1 && this.usuario.indAtivo) {
        this.$refs.avisoAnexoDialog.open();
      } else if (this.usuario.indProcurador && this.isDataAlterada && !this.isUsuarioAprovador) {
        this.openModalAlteracaoData();
      } else {
        this.$refs.confirmDialog.open();
      }
    },
    openSolicitarAprovacaoDialog(dataAlterada = false) {
      if (this.quantidadeArquivos < 1) {
        this.$refs.avisoAnexoUsuarioAntigoDialog.open();
      } else {
        if (!this.$refs.form.validate()) {
          return;
        }
        this.save('AGUARDANDO_APROVACAO', dataAlterada);
      }
    },
    openModalJustificativaAprovacao(status, titleModal) {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.titleJustificativaModal = titleModal;
      this.statusAprovacaoUsuario = status;
      this.statusAprovadoRepresentanteLegal = status;
      this.$refs.modalJustificativaAprovacao.open();
    },
    aprovarReprovarUsuario(justificativa) {
      this.justificativaAprovacaoReprovacaoUsuario = justificativa;
      this.save(this.statusAprovadoRepresentanteLegal);
    },
    buscarExtensoes(idGrupoFornecedor = null) {
      const params = {
        idNivelExtensao: 3,
        indUsuarioExterno: true,
      };
      if (idGrupoFornecedor) {
        params.idExtensao = idGrupoFornecedor;
      }
      return this.extensaoResources.listarPorUsuario(params)
        .then((res) => {
          this.extensoes = res.data.resposta;
          if (this.extensoes.length === 1) {
            const [grupoForn] = this.extensoes;
            this.usuario.grupofornecedor = grupoForn;
            this.usuario.id_grupofornecedor = grupoForn.id;
            this.grupoFornecedorEdicao = grupoForn;
            this.idGrupoFornecedorEdicao = grupoForn.id;
            this.$refs.containerUsuario.refresh();
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    save(statusFluxo = null, dataAlterada = false) {
      this.aplicarTrimCamposTexto();
      const objArmazenamento = {
        ...this.usuario,
        ...this.$refs.containerUsuario.getValoresDependencias(),
      };
      if (statusFluxo && statusFluxo === 'REPROVADO') {
        objArmazenamento.indAtivo = false;
      } else if (statusFluxo === null && this.podeInativarUsuario()) {
        statusFluxo = 'EM_CADASTRO';
      } else if (statusFluxo === null && this.usuario.statusFluxo === 'APROVADO' && this.isUsuarioAprovador) {
        statusFluxo = this.usuario.statusFluxo;
      } else if (statusFluxo === null && this.usuario.statusFluxo && this.usuario.indAtivo && this.isUsuarioAprovador) {
        statusFluxo = 'APROVADO';
      }
      objArmazenamento.statusFluxo = statusFluxo;
      objArmazenamento.justificativaAprovacao = this.justificativaAprovacaoReprovacaoUsuario;
      objArmazenamento.dataAlterada = dataAlterada || null;
      if (this.usuario.dtaInicioVigenciaPrevia) {
        objArmazenamento.dtaInicioVigenciaPrevia = this.usuario.dtaInicioVigenciaPrevia;
        objArmazenamento.dtaFimVigenciaPrevia = this.usuario.dtaFimVigenciaPrevia;
      }
      this.exibeAlertaLgpd();
      forceLoading();
      if (this.isNovoCadastro) {
        if (this.usuario.indUsuarioExternoMaster) {
          this.requestGravar(objArmazenamento, this.usuarioResource.gravar);
        } else {
          this.verificaRepresentacaoConjunto(objArmazenamento, 'salvar');
        }
      } else if (this.usuario.indUsuarioExternoMaster) {
        this.requestAtualizar(objArmazenamento, this.usuarioResource.atualizar);
      } else {
        this.verificaRepresentacaoConjunto(objArmazenamento, 'atualizar');
      }
    },
    podeInativarUsuario() {
      return this.usuario.statusFluxo === 'APROVADO' && !this.usuario.indAtivo && this.isUsuarioAprovador;
    },
    verificaRepresentacaoConjunto(objArmazenamento, acao) {
      const idGrupoFornecedor = objArmazenamento.grupofornecedor.id,
        { indRepresentacaoConjunto } = objArmazenamento;
      this.usuarioResource.verificarRepresentacaoConjunto({ idGrupoFornecedor })
        .then((resposta) => {
          const res = resposta.body;
          const possuiRepresentacaoConjunto = res.possuiRepresentacaoConjunto !== undefined
            ? res.possuiRepresentacaoConjunto : false;
          if (possuiRepresentacaoConjunto === indRepresentacaoConjunto || !res.qtdRepresentantes) {
            if (acao === 'salvar') {
              this.requestGravar(objArmazenamento, this.usuarioResource.gravar);
            } else {
              this.requestAtualizar(objArmazenamento, this.usuarioResource.atualizar);
            }
          } else {
            let msg = this.$t('message.alterou_rep_conjunto_marcar_todos', res);
            if (!indRepresentacaoConjunto) {
              msg = this.$t('message.alterou_rep_conjunto_desmarcar_todos', res);
            }
            forceHideLoading();

            setTimeout(() => this.$refs.confirmDialogAlterandoRepConjuntoGrupo
              .openWithParams(
                this.$t('title.atencao'),
                msg,
                (ok) => {
                  if (ok) {
                    const params = {
                      idGrupoFornecedor,
                      indRepresentacaoConjunto,
                    };
                    forceLoading();
                    this.usuarioResource.atualizarRepresentacaoConjunto(params, params)
                      .then(() => {
                        if (acao === 'salvar') {
                          this.requestGravar(objArmazenamento, this.usuarioResource.gravar);
                        } else {
                          this.requestAtualizar(objArmazenamento, this.usuarioResource.atualizar);
                        }
                      })
                      .catch((err) => {
                        this.$error(this, err);
                      });
                  }
                },
              ));
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    exibeAlertaLgpd() {
      if (this.indAmbienteProdutivo) return;

      const alertaLgpd = this.$t('message.lgpd');
      alert(alertaLgpd); // eslint-disable-line
    },
    aplicarTrimCamposTexto() {
      this.usuario.nomUsuario = this.usuario.nomUsuario.normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim();
      this.usuario.email = this.usuario.email.trim();
      this.usuario.nome = this.usuario.nome.trim();
      if (this.usuario.matricula) {
        this.usuario.matricula = this.usuario.matricula.trim();
      }
      if (this.usuario.sobrenome) {
        this.usuario.sobrenome = this.usuario.sobrenome.trim();
      }
      if (this.usuario.cargo) {
        this.usuario.cargo = this.usuario.cargo.trim();
      }
      if (this.usuario.documento) {
        this.usuario.documento = this.usuario.documento.trim();
      }
    },
    requestAtualizar(object, resource) {
      resource({ id: object.id }, object)
        .then(() => {
          if (this.existeFluxosImpactadosPorInativacao && this.indHabilitaAlterarFluxosImpactados && this.desabilitaFluxoAprovacaoUsuarioInativo) {
            skipLoading();
            Promise.all([
              this.enviarAcoesParaAnalise(),
              this.enviarApuracoesParaAnalise(),
              this.enviarContratosParaAnalise(),
            ]).then(() => {
              this.enviaEmailAvisoInativacao();
              this.dadosFluxosAprovacaoImpactados = [];
              this.indHabilitaAlterarFluxosImpactados = false;
            });
          }
          if (this.termoAceite && this.termoAceite.indAtivo && !this.existeTermoAceite && !this.usuario.indUsuarioExternoMaster
              && this.usuarioLogadoFornecedor) {
            this.salvarTermoAceite();
          }
          forceHideLoading();
          window.setTimeout(() => {
            this.$router.push({ name: 'usuario-fornecedor' });
            this.$toast(this.$t('message.atualizado_com_sucesso'));
          }, 500);
        })
        .catch((err) => {
          forceHideLoading();
          this.$toast(err.data.error);
        });
    },
    requestGravar(object, resource) {
      resource(object)
        .then((res) => {
          this.usuario.id = res.data;
          if (this.termoAceite.indAtivo && !this.existeTermoAceite && !this.usuario.indUsuarioExternoMaster && this.usuarioLogadoFornecedor) {
            this.salvarTermoAceite();
          }
          window.setTimeout(() => {
            forceHideLoading();
            if (this.usuario.indUsuarioExternoMaster && this.podeCadastrarAnexos) {
              this.editUsuario(this.usuario.id);
            } else {
              this.$router.push({ name: 'usuario-fornecedor' });
              this.$toast(this.$t('message.adicionado_confira_tabela'));
            }
          }, 500);
        })
        .catch((err) => {
          forceHideLoading();
          this.$toast(err.data.error);
        });
    },
    carregarUsuario(id) {
      this.usuarioResource.buscar({ id })
        .then((res) => {
          this.editar(res.body);
          forceHideLoading();
        })
        .catch((err) => {
          this.$error(this, err);
          forceHideLoading();
        });
    },
    editar(usuario) {
      this.usuario = new Usuario();
      this.usuario.construirAPartirDaConsultaCompleta({ ...usuario });
      this.isProcurador(this.usuario.indProcurador, true);
      if (this.canEdit && !this.isNovoCadastro
        && ((this.podeCadastrarFornecedorMaster && !this.podeCadastrarFornecedorRepresentanteLegal && usuario.indUsuarioExternoMaster)
        || (this.podeCadastrarFornecedorRepresentanteLegal && !this.podeCadastrarFornecedorMaster && !usuario.indUsuarioExternoMaster))) {
        this.somenteLeitura = true;
      }
      if (!this.somenteLeitura && this.$route.params.ind_novo_usuario && this.usuario.indUsuarioExternoMaster
        && this.podeCadastrarAnexos) {
        this.$refs.avisoAnexoDialog.open();
      }
      if (this.usuario.dtaInicioVigencia && this.usuario.dtaFimVigencia) {
        this.dtaInicioVigenciaOriginal = copyObject(this.usuario.dtaInicioVigencia);
        this.dtaFimVigenciaOriginal = copyObject(this.usuario.dtaFimVigencia);
      }
      if (this.idGrupoFornecedorEdicao !== null && this.grupoFornecedorEdicao) {
        this.usuario.grupofornecedor = this.grupoFornecedorEdicao;
        this.usuario.id_grupofornecedor = this.idGrupoFornecedorEdicao;
      }
      if (this.usuario.statusFluxo === 'APROVADO' && !this.usuario.indProcurador && this.usuario.dtaInicioVigencia && this.usuario.dtaFimVigencia) {
        this.usuarioAprovadoNaoProcurador = true;
      }
      if (this.usuario.dtaInicioVigencia) {
        this.dtaInicioVigencia = this.usuario.dtaInicioVigencia;
        this.dtaFimVigencia = this.usuario.dtaFimVigencia;
      }
      this.indAtivoOriginal = this.usuario.indAtivo;

      montarEstruturaDinamica(usuario, this.metadadosUsuario, this.usuario);
      this.usuarioSemEdicao = copyObject(usuario);
    },
    setDialogContent(content, action, from) {
      this.dialog.content = content || this.$t('message.salvar_novo_registro');
      this.dialog.action = action || this.save;
      this.dialog.from = from || this.dialog.from;
    },
    buscarPerfis(content = '') {
      const params = {
        autocomplete: content,
        limit: 1000,
        page: 0,
      };
      return this.perfilResource.selecao(params)
        .then((res) => {
          if (res.data) {
            this.perfis = res.data.filter((perfil) => {
              perfil.indPerfilUsuarioExterno = this.converterStringParaBoolean(perfil.indPerfilUsuarioExterno);
              return perfil.indPerfilUsuarioExterno;
            });
          }
          this.selecionarPerfilAuto();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    selecionarPerfilAuto() {
      if (this.isUsuarioExternoMaster) {
        this.usuario.idPerfil = this.usuarioLogado.idPerfil;
      } else if (this.perfis.length === 1) {
        this.usuario.idPerfil = this.perfis[0].id;
      }
    },
    converterStringParaBoolean(perfil) {
      return (String(perfil).toLowerCase() === 'true');
    },
    triggerClick(id) {
      document.getElementById(id).click();
    },
    buscarTermoAceite() {
      const param = { objetivo: 'CRIACAO_USUARIO_FORNECEDOR' };
      this.termoAceiteResources.buscarTermoPorObjetivo(param).then((resposta) => {
        this.termoAceite = resposta.data;
      }, (err) => {
        this.$error(this, err);
      });
    },
    buscarChaveFluxoRepresentanteLegal() {
      this.configSistemaResource.buscarChave({ chave: 'FLUXO_APROVACAO_USR_FORNE_REP_LEG' })
        .then((resp) => {
          this.indFluxoAprovacaoRepreLegal = resp.body;
        });
    },

    salvarTermoAceite() {
      this.termoAceite.id_usuario_cadastrado = this.usuario.id;
      this.termoAceiteResources.salvarAceiteCadastroUsuario(this.termoAceite)
        .then()
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    triggerAtivaUsuario(value) {
      if (!value && this.isNovoCadastro) {
        this.usuario.indUsuarioExternoMaster = false;
      }
    },
    setQuantidadeArquivos(quantidade = 0) {
      this.quantidadeArquivos = quantidade;
    },
    desativarUsuario() {
      this.usuario.indAtivo = false;
    },
    triggerAtivaUsuarioMaster() {
      if (!this.usuario.indUsuarioExternoMaster) {
        this.usuario.indProcurador = false;
        this.flagProcurador = false;
      }
      if (this.usuario.indUsuarioExternoMaster && this.quantidadeArquivos < 1) {
        this.usuario.indAtivo = false;
      }
      this.$forceUpdate();
    },
    editUsuario(id) {
      this.$router.push({ name: 'editarUsuarioFornecedor', params: { id, ind_novo_usuario: true } });
      window.setTimeout(() => {
        window.location.reload();
      }, 500);
    },
    openModalAlteracaoData() {
      this.$refs.modalDataAlteracao.open();
    },
    changeIndAtivo(usuario) {
      if (!this.isNovoCadastro && this.indAtivoOriginal && !usuario.indAtivo) {
        const dados = {
          idUsuario: usuario.id,
          nomeFornecedor: this.usuario.nomeSobrenome,
          dadosFluxosAprovacaoImpactados: this.dadosFluxosAprovacaoImpactados,
        };
        setTimeout(() => this.$refs.modalDadosFluxosDeAprovacaoImpactadosPorInativacaoUsuario.open(dados), 2E2);
      }
      if (this.isDtaFinalMenorQueDtaAtual()) {
        this.usuario.indAtivo = false;
        this.panelKey += 1;
        this.$toast(this.$t('errors.data.final_menor_data_atual'));
      }
      if (this.usuario.indUsuarioExternoMaster && this.indFluxoAprovacaoRepreLegal
          && this.permissaoSolicitarAprovacao && this.usuario.indAtivo && !this.isUsuarioAprovador) {
        this.usuario.indAtivo = false;
        this.panelKey += 1;
        setTimeout(() => this.$refs.confirmDialogSolicitarAprovacao
          .openWithParams(
            this.$t('title.atencao'),
            this.$t('message.solicitar_aprovacao_usuario'),
            (ok) => {
              if (ok) {
                this.openSolicitarAprovacaoDialog();
              }
            },
          ));
      }
    },
    isDtaFinalMenorQueDtaAtual() {
      return this.usuario.indProcurador && !this.isDataNaoVencida() && this.usuario.indAtivo && this.isUsuarioAprovador;
    },
    cancelarEdicaoData() {
      this.usuario.dtaInicioVigencia = this.dtaInicioVigenciaOriginal;
      this.usuario.dtaFimVigencia = this.dtaFimVigenciaOriginal;
      this.menuKey += 1;
    },
    buscaTermoAceiteUsuarioCadastro(idUsuario) {
      this.usuarioResource.buscaTermoAceiteUsuarioCadastro(({ idUsuario }))
        .then((res) => {
          this.existeTermoAceite = res.body;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    representacaoConjuntoAlterada() {
      if (!this.usuario.indRepresentacaoConjunto) {
        this.usuario.indAprovadorAnexoComercial = false;
      }
      this.aprovadorKey += 1;
    },
    setarPaginaCarregada(indPaginaCarregada) {
      this.isPaginaCarregada = indPaginaCarregada;
    },
    indAtivoPadrao() {
      if (!this.$route.params.id && this.usuario.indUsuarioExternoMaster) {
        this.usuario.indAtivo = false;
      }
    },
    isDataFimMaiorInicio() {
      const dtInicio = this.moment(this.usuario.dtaInicioVigencia, 'YYYY-MM-DD');
      const dtFinal = this.moment(this.usuario.dtaFimVigencia, 'YYYY-MM-DD');
      return dtFinal.isAfter(dtInicio);
    },
    isDataNaoVencida() {
      if (!this.usuario.dtaFimVigencia) {
        return true;
      }
      const hoje = this.moment();
      const dtFinal = this.moment(this.usuario.dtaFimVigencia, 'YYYY-MM-DD');
      return dtFinal.isSameOrAfter(hoje);
    },
    setarDataInicioNoUsuario() {
      this.usuario.dtaInicioVigencia = this.dtaInicioVigencia;
    },
    setarDataFimNoUsuario() {
      this.usuario.dtaFimVigencia = this.dtaFimVigencia;
    },
    buscaDadosPerfilAprovador() {
      return this.perfilResource.buscaIdPerfilUsuarioAprovador()
        .then((res) => {
          this.idPerfilAprovador = res.data;
        });
    },
    cancelaAcaoInativar() {
      this.usuario.indAtivo = true;
    },
    alterarStatusEntidadesImpactadasPorInativacaoUsuario() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.indHabilitaAlterarFluxosImpactados = true;
      this.save();
    },
    buscaDadosFluxosAprovacaoImpactados(idUsuario) {
      this.usuarioResource.dadosFluxosAprovacaoImpactados(({ idUsuario }))
        .then((res) => {
          this.dadosFluxosAprovacaoImpactados = res.body;
          if (Object.keys(this.dadosFluxosAprovacaoImpactados).length > 0) {
            this.dadosFluxosAprovacaoImpactados.idsCampanha = this.dadosFluxosAprovacaoImpactados.idsCampanha && this.dadosFluxosAprovacaoImpactados.idsCampanha.length ? this.dadosFluxosAprovacaoImpactados.idsCampanha.split(',').map(Number) : [];
            this.dadosFluxosAprovacaoImpactados.idsApuracao = this.dadosFluxosAprovacaoImpactados.idsApuracao && this.dadosFluxosAprovacaoImpactados.idsApuracao.length ? this.dadosFluxosAprovacaoImpactados.idsApuracao.split(',').map(Number) : [];
            this.dadosFluxosAprovacaoImpactados.idsContrato = this.dadosFluxosAprovacaoImpactados.idsContrato && this.dadosFluxosAprovacaoImpactados.idsContrato.length ? this.dadosFluxosAprovacaoImpactados.idsContrato.split(',').map(Number) : [];
            if (this.dadosFluxosAprovacaoImpactados.idsCampanha.length > 0 || this.dadosFluxosAprovacaoImpactados.idsApuracao.length > 0
             || this.dadosFluxosAprovacaoImpactados.idsContrato.length > 0) {
              this.existeFluxosImpactadosPorInativacao = true;
            }
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    enviarAcoesParaAnalise() {
      const listaAcoes = this.dadosFluxosAprovacaoImpactados.idsCampanha;
      const dataAtual = new Date();
      const horaInativacao = dataAtual.toLocaleString();
      const promises = [];
      if (listaAcoes.length) {
        listaAcoes.forEach((idAcao) => {
          const params = {
            idAcao,
            observacao: this.$t('message.enviado_analise_automaticamente_por_inativacao_fornecedor', { nomeFornecedor: this.usuario.nomeSobrenome, horaInativacao }),
          };
          const promise = this.workflowAcaoResource.enviarAnaliseAcaoPorInativacaoFornecedor(params, params)
            .then(() => {
            })
            .catch((err) => {
              this.$error(this, err);
            });
          promises.push(promise);
        });
      }
      return Promise.all(promises);
    },
    enviarApuracoesParaAnalise() {
      const listaApuracoes = this.dadosFluxosAprovacaoImpactados.idsApuracao;
      const dataAtual = new Date();
      const horaInativacao = dataAtual.toLocaleString();
      const promises = [];
      if (listaApuracoes.length) {
        listaApuracoes.forEach((idApuracao) => {
          const params = {
            idApuracao,
            observacao: this.$t('message.enviado_analise_automaticamente_por_inativacao_fornecedor', { nomeFornecedor: this.usuario.nomeSobrenome, horaInativacao }),
          };
          const promise = this.workflowApuracaoAcaoResource.enviarAnaliseApuracaoPorInativacaoFornecedor(params, params)
            .then(() => {
            })
            .catch((err) => {
              this.$error(this, err);
            });
          promises.push(promise);
        });
      }
      return Promise.all(promises);
    },
    enviarContratosParaAnalise() {
      const listaContratos = this.dadosFluxosAprovacaoImpactados.idsContrato;
      const dataAtual = new Date();
      const horaInativacao = dataAtual.toLocaleString();
      const promises = [];
      if (listaContratos.length) {
        const promise = listaContratos.forEach((idContrato) => {
          const params = {
            idContrato,
            observacao: this.$t('message.enviado_analise_automaticamente_por_inativacao_fornecedor', { nomeFornecedor: this.usuario.nomeSobrenome, horaInativacao }),
          };
          this.workflowContratoResource.enviarAnaliseContratoPorInativacaoFornecedor(params, params)
            .then(() => {
            })
            .catch((err) => {
              this.$error(this, err);
            });
        });
        promises.push(promise);
      }
      return Promise.all(promises);
    },
    buscarChaveDesabilitarFluxoAprovacaoUsuarioInativo() {
      this.configSistemaResource.buscarChave({ chave: 'DESABILITAR_FLUXO_APROVACAO_USUARIO_INATIVO' })
        .then((resp) => {
          this.desabilitaFluxoAprovacaoUsuarioInativo = resp.body;
        });
    },
    enviaEmailAvisoInativacao() {
      const params = {
        listaCampanhas: this.dadosFluxosAprovacaoImpactados.idsCampanha,
        listaApuracoes: this.dadosFluxosAprovacaoImpactados.idsApuracao,
        listaContratos: this.dadosFluxosAprovacaoImpactados.idsContrato,
        idUsuario: this.idUsuario,
      };

      enviarEmailAvisoInativacaoFornecedor(params, this.$resource).then(() => {})
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
  beforeMount() {
    forceLoading();
    Promise.all([
      this.popularMetadadosUsuario(),
      this.buscarTermoAceite(),
      this.buscarChaveFluxoRepresentanteLegal(),
      this.buscarChaveDesabilitarFluxoAprovacaoUsuarioInativo(),
      this.buscarPerfis(),
      this.buscaDadosPerfilAprovador(),
    ]).then(() => {
      if (this.$route.params.id) {
        this.idUsuario = parseInt(this.$route.params.id, 10);
        Promise.all([
          this.carregarUsuario(this.idUsuario),
          this.buscaTermoAceiteUsuarioCadastro(this.idUsuario),
          this.buscaDadosFluxosAprovacaoImpactados(this.idUsuario),
        ]).then(() => this.setarPaginaCarregada(true));
      } else {
        this.setarPaginaCarregada(true);
        forceHideLoading();
      }
    });
  },
  mounted() {
    if (this.podeCadastrarFornecedorRepresentanteLegal && !this.podeCadastrarFornecedorMaster) {
      this.usuario.indUsuarioExternoMaster = true;
    }
    if (this.podeCadastrarFornecedorMaster) {
      this.usuario.indUsuarioExternoMaster = false;
    }
    if (this.usuarioLogadoFornecedor) {
      this.buscarExtensoes(this.usuarioLogado.idGrupoFornecedor);
    } else {
      this.buscarExtensoes(null);
    }
    window.setTimeout(() => {
      this.indAtivoPadrao();
    }, 500);
  },
};
</script>
